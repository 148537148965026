// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Overlay/variables";
@import "../Drawer/variables";
@import "../Button/variables";
@import "../Icon/variables";
@import "../Action/variables";
@import "../Link/variables";
@import "../Section/variables";
@import "../Text/variables";
@import "../Heading/variables";

@dialog--prefix-cls: ~"@{cls-prefix}dialog";

.dialog-header-actions() {
    position: absolute;
    z-index: 10;
    inset-inline-end: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-inline-end: calc(16px - var(--_overlay-side-banner-width));
    inline-size: calc(var(--_overlay-side-banner-width) - 32px);

    --dialogHeaderButtonBackgroundHover: #efefef;
    --dialogHeaderButtonBorder: inset 0 0 0 1px rgb(29 72 112 / 0.1);
}

.@{dialog--prefix-cls} {
    .@{overlay--prefix-cls}__content {
        border-radius: 16px;
        background-color: transparent;
    }

    &__header {
        position: relative;
        flex: none;
    }

    &__header-inner {
        padding-inline: 24px;
        background-color: #fff;
    }

    &__header-body {
        display: flex;
        align-items: flex-start;
        padding-block: 20px 16px;
        border-block-end: 1px solid #d3d3de;
    }

    &__header-content {
        flex-grow: 1;
    }

    & &__title {
        margin-block-end: 0;
        font-size: @heading--h2-font-size;
        font-weight: 400;
        line-height: @heading--h2-line-height;

        > :last-child {
            margin-block-end: 0;
        }
    }

    & &__title + &__subtitle {
        margin-block-start: 12px;
    }

    &__header-actions {
        display: flex;
        align-items: center;
        min-block-size: 40px;
        margin-block: -4px;
        margin-inline-end: -8px;
    }

    @media (min-width: 850px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--xs &__header-actions {
            .dialog-header-actions();
        }
    }

    @media (min-width: 1000px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--sm &__header-actions {
            .dialog-header-actions();
        }
    }

    @media (min-width: 1240px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--md &__header-actions {
            .dialog-header-actions();
        }
    }

    @media (min-width: 1540px) {
        .@{overlay--prefix-cls}--side.@{overlay--prefix-cls}--lg &__header-actions {
            .dialog-header-actions();
        }
    }

    &__header-actions > .@{button--prefix-cls},
    &__header-actions > .@{action--prefix-cls},
    &__header-actions > .@{link--prefix-cls} {
        margin-inline-start: 8px;
    }

    &__header-actions > .@{button--prefix-cls} {
        border-radius: 8px;
    }

    &__header-actions > .@{button--prefix-cls}--ghost,
    &__header-actions > .@{button--prefix-cls}--ghost[disabled],
    &__header-actions > .@{button--prefix-cls}--ghost.@{button--prefix-cls}--disabled,
    &__header-actions > .@{button--prefix-cls}--ghost.@{button--prefix-cls}--loading {
        &,
        &:hover,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &:active {
            background-color: var(--dialogHeaderButtonBackground, #fff);
            box-shadow: var(--dialogHeaderButtonBorder, none);
        }
    }

    &__header-actions > .@{button--prefix-cls}--ghost:hover,
    &__header-actions > .@{button--prefix-cls}--ghost:focus,
    &__header-actions > .@{button--prefix-cls}--ghost.@{button--prefix-cls}--focused,
    &__header-actions > .@{button--prefix-cls}--ghost.@{button--prefix-cls}--hovered {
        background-color: var(--dialogHeaderButtonBackgroundHover, #f4f4f4);
    }

    &__header-actions > .@{button--prefix-cls}--ghost:focus,
    &__header-actions > .@{button--prefix-cls}--ghost.@{button--prefix-cls}--focused {
        box-shadow: 0 0 0 4px rgb(0 149 255 / 0.24);
    }

    &__heading .@{button--prefix-cls}--ghost:hover,
    &__heading .@{button--prefix-cls}--ghost:focus,
    &__heading .@{button--prefix-cls}--ghost:active {
        background-color: rgb(0 0 0 / 0.05);
        .@{button--prefix-cls}__icon {
            color: rgb(255 255 255 / 0.9);
        }
    }

    &__heading .@{button--prefix-cls}--ghost:active {
        background-color: rgb(0 0 0 / 0.09);
    }

    &__banner {
        flex: none;
    }

    &__banner + &__header-inner {
        display: flex;
        align-items: flex-end;
        position: absolute;
        inset-block-start: 0;
        inline-size: 100%;
        block-size: 100%;
        box-sizing: border-box;
        padding: 0;
        background: linear-gradient(to top, rgb(0 0 0 / 0.5) 22.67%, rgb(0 0 0 / 0) 65.83%);
    }

    &__banner + &__header-inner &__header-body {
        block-size: 100%;
        inline-size: 100%;
        box-sizing: border-box;
        padding-inline: 24px;
        border-block-end: none;
    }

    &__banner + &__header-inner &__header-content {
        align-self: flex-end;
    }

    &__banner + &__header-inner &__title,
    &__banner + &__header-inner &__subtitle,
    &__banner + &__header-inner &__subtitle .@{text--prefix-cls}--muted {
        color: #fff;
    }

    &__banner + &__header-inner &__header-actions {
        position: absolute;
        inset-block-start: 20px;
        inset-inline-end: 24px;
        margin-inline-start: 8px;
    }

    &__banner,
    &__image {
        text-align: center;
    }

    &__banner-inner,
    &__banner > img,
    &__banner > svg {
        inline-size: 100%;
        block-size: auto;
    }

    &__image {
        padding-block-end: 16px;
    }

    &__image-inner {
        max-inline-size: 100%;
        block-size: auto;
    }

    &__content {
        flex: 1;
        padding-block: 20px 24px;
        padding-inline: 24px;
        max-block-size: ~"calc(100vh - 112px)";
        overflow-y: auto;
        word-break: break-word;
        background-color: #fff;
    }

    &__buttons {
        margin-block-start: 16px;
        padding-block-start: 0;
        text-align: center;

        .@{button--prefix-cls} {
            min-inline-size: 90px;
            margin-block: 0;
            margin-inline: 0 16px;
            padding-block: 10px;
            padding-inline: 24px;
            font-weight: 600;
            font-size: 14px;
        }
    }

    &__form-footer,
    &__buttons {
        position: sticky; // stylelint-disable-line plugin/no-unsupported-browser-features
        inset-block-end: -24px;
        margin-block: 20px -24px;
        margin-inline: -24px;
        padding-block: 16px 24px;
        padding-inline: 24px;
        background-color: #fff;
        text-align: start;

        &::before {
            content: "";
            display: block;
            margin-block: -16px 16px;
            border-block-start: 1px solid #d3d3de;
        }
    }

    .@{section-item--prefix-cls}:not(.@{section-item--prefix-cls}--vertical)
        .@{section-item--prefix-cls}__title {
        inline-size: 160px;
    }

    &__form-footer .@{section-item--prefix-cls} .@{section-item--prefix-cls}__title {
        display: none;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;