// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

@paragraph--prefix-cls: ~"@{cls-prefix}paragraph";

.@{paragraph--prefix-cls} {
    margin-block-end: @base-margin-vertical;
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;