// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables.less";

@icons-loader--prefix-cls: ~"@{cls-prefix}icons-loader";
@icons-loader--icon-color: fade(@token-color-dark-1, 50%);
@icons-loader--icon-animation: ~"@{icons-loader--prefix-cls}--icon-animation";

.@{icons-loader--prefix-cls} {
    --_icons-loader-size: calc(var(--icons-loader-size, 64) * 1px);

    margin: auto;
    inline-size: var(--_icons-loader-size);
    block-size: var(--_icons-loader-size);
    position: relative;

    &__icon {
        display: grid;
        place-items: center;
        inline-size: 100%;
        block-size: 100%;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        color: @icons-loader--icon-color;
        transform: translate(100%, 16px) scale(0.5);
        opacity: 0;
        animation: @icons-loader--icon-animation 3s ease-in-out infinite;
    }

    &__icon--1 {
        animation-delay: 0.5s;
    }

    &__icon--2 {
        animation-delay: 2s;
    }

    @keyframes @icons-loader--icon-animation {
        0% {
            transform: translate(100%, 16px) scale(0.5);
            opacity: 0;
        }

        10% {
            transform: translate(0, 0) scale(1);
            opacity: 1;
        }

        50% {
            transform: translate(0, 0) scale(1);
            opacity: 1;
        }

        60% {
            transform: translate(-100%, 16px) scale(0.5);
            opacity: 0;
        }

        100% {
            transform: translate(-100%, 16px) scale(0.5);
            opacity: 0;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;