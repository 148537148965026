// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

@console-output--prefix-cls: ~"@{cls-prefix}console-output";

@console-output--border-color: @token-color-grey-4;
@console-output--background: @token-color-grey-2;
@console-output--color: @base-body-color;
@console-output--font-size: @base-body-font-size;
@console-output--line-height: @base-body-line-height;
@console-output--font-family: @base-code-font-family;

@console-output--cursor-color: @token-color-grey-8;
@console-output--cursor-animation: ~"@{console-output--prefix-cls}-cursor-blink";

/* ========================================================================
   Console Output
 ========================================================================== */

.@{console-output--prefix-cls} {
    box-sizing: border-box;
    min-block-size: 200px;
    max-block-size: 25em;
    max-inline-size: 80em;
    margin-block-end: @base-margin-vertical;
    padding-block: 12px 16px;
    padding-inline: 16px;
    border: 1px solid @console-output--border-color;
    border-radius: 2px;
    background-color: @console-output--background;
    font: @console-output--font-size / @console-output--line-height @console-output--font-family;
    color: @console-output--color;
    tab-size: 4;
    overflow: auto;
    overflow-x: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;

    &__cursor {
        display: inline-block;
        inline-size: 0.4615em;
        block-size: 1em;
        margin-inline-start: 0.2308em;
        vertical-align: -1px;
        background-color: @console-output--cursor-color;
        animation: @console-output--cursor-animation 1s infinite steps(2, start);
    }

    &--wide {
        max-inline-size: 100%;
    }
}

@keyframes @console-output--cursor-animation {
    80% {
        visibility: hidden;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;