// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Tabs/variables";
@import "../Heading/variables";
@import "../Layout/variables";

.@{page-header--prefix-cls} {
    &__inner,
    &__tabs-inner {
        inline-size: 100%;
        margin-inline: auto;
        padding-inline: 16px;
        box-sizing: border-box;

        @media (min-width: @screen-sm) {
            padding-inline: 24px;
        }

        .@{layout--prefix-cls}--sm & {
            max-inline-size: 640px;
        }

        .@{layout--prefix-cls}--md & {
            max-inline-size: 960px;
        }

        .@{layout--prefix-cls}--lg & {
            max-inline-size: 1200px;
        }
    }

    &__inner {
        display: grid;
        padding-block: 16px 0;

        > * + * {
            margin-block-start: 16px;
        }
    }

    &__breadcrumbs {
        margin-block-end: 4px;
    }

    &__breadcrumbs + &__title {
        margin-block-start: 0;
    }

    &__title {
        display: flex;
        align-items: flex-start;

        .@{heading--prefix-cls} {
            margin: 0;
        }
    }

    &__title-left-addon,
    &__title-right-addon {
        flex: none;
        display: flex;
        align-items: center;
        min-block-size: @heading--h1-font-size * @heading--h1-line-height;
    }

    &__title-left-addon {
        margin-inline-end: 8px;
    }

    &__title-right-addon {
        margin-inline-start: 8px;
    }

    &__content > :last-child,
    &__tabs .@{tabs--prefix-cls}__nav {
        margin-block-end: 0;
    }

    &__inner + &__tabs {
        margin-block-start: 16px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;