// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Button/variables";
@import "../Dropdown/variables";

@split-button--prefix-cls: ~"@{cls-prefix}split-button";

.@{split-button--prefix-cls} {
    &__dropdown-trigger .@{button--prefix-cls} {
        .@{dropdown--prefix-cls}--opened& {
            color: var(--pul-button--color-active, var(--pul-color-text));
            box-shadow: @button--shadow-active;
            background-color: var(
                --pul-button--bg-color-active,
                var(--pul-color-bg-secondary-pressed)
            );
        }
    }

    & .@{button--prefix-cls} + &__dropdown-trigger {
        margin-inline-start: 1px;
    }

    & .@{button--prefix-cls} + &__dropdown-trigger .@{button--prefix-cls} {
        border-start-start-radius: 0;
        border-start-end-radius: 2px;
        border-end-start-radius: 0;
        border-end-end-radius: 2px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;