// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

@form-field-radio-buttons--prefix-cls: ~"@{cls-prefix}form-field-radio-buttons";

.@{form-field-radio-buttons--prefix-cls} {
    &__radio + &__radio {
        margin-block-start: 8px;
    }

    &__content {
        padding-inline-start: 28px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;