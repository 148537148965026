// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

@toolbar--prefix-cls: ~"@{cls-prefix}toolbar";

.@{toolbar--prefix-cls} {
    display: flex;
    margin-block-end: 16px;
    align-items: center;

    &__group-item:not(:last-child) {
        margin-inline-end: 4px;
    }

    &__group {
        display: flex;
        white-space: nowrap;
        align-items: center;

        &:not(:last-child) {
            margin-inline-end: 16px;
        }

        &--grow {
            flex-grow: 1;
        }
    }

    &__expander {
        flex-grow: 1;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;