// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Button/variables";

.@{segmented-control--prefix-cls} {
    display: flex;
    align-items: center;

    &--inline {
        display: inline-flex;
    }

    &__label {
        flex: none;
        padding-inline-end: 8px;
    }

    &--vertical,
    &--collapsed {
        flex-wrap: wrap;
    }

    &--vertical > &__label {
        flex: 1 1 100%;
        padding-block-end: 8px;
        padding-inline-end: 0;
    }

    .@{button--prefix-cls}:not(.@{button--prefix-cls}--empty):not(.@{button--prefix-cls}--caret) {
        padding-inline: 12px;
        min-inline-size: auto;

        .@{button--prefix-cls}__icon {
            margin-inline-start: -4px;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;