// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Input/variables";

@form-field-password--prefix-cls: ~"@{cls-prefix}form-field-password";

.@{form-field-password--prefix-cls} {
    &__control {
        display: inline-flex;
        max-inline-size: 100%;

        &--fill {
            inline-size: @input--size-fill;
        }
    }

    &__field {
        display: inline-flex;
        max-inline-size: 100%;

        &--md {
            inline-size: @input--size-md;
        }

        &--lg {
            inline-size: @input--size-lg;
        }

        &--xl {
            inline-size: @input--size-xl;
        }

        &--fill {
            inline-size: @input--size-fill;
        }
    }

    &__button--generate {
        margin-inline-start: 4px;
        flex-shrink: 0;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;