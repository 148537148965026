// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "variables";

.@{overlay--prefix-cls} {
    --_overlay-side-banner-width: var(--overlay-side-banner-width, 340px);

    inset-block-start: 0;
    inset-inline-start: 0;
    inline-size: 100%;
    block-size: 100%;
    display: flex;
    position: fixed;

    &__content {
        position: relative;
        overflow: auto;
        background-color: #fff;
        box-shadow: @overlay--shadow;
        max-inline-size: var(--overlay-content-width, none);
    }

    &__content,
    &__body {
        display: flex;
        outline: none;
    }

    &__body {
        flex-direction: column;
        flex-grow: 1;
        max-block-size: 100%;
        min-inline-size: 0;
    }

    &__side {
        display: none;
        align-items: var(--overlay-side-banner-align, center);
        flex: 0 0 var(--_overlay-side-banner-width);
        background: var(--overlay-side-banner-background, #f3f4f5);
    }

    &__side-image,
    &__side > img,
    &__side > svg {
        margin-block: 0;
        margin-inline: auto;
        max-inline-size: var(--_overlay-side-banner-width);
        max-block-size: 100%;
    }

    &--left {
        flex: 1 0 auto;
        flex-direction: row;
    }

    &--left &__content {
        border-start-end-radius: 16px;
        border-end-end-radius: 16px;
        transform: translate(-100%);
        transition: transform 300ms ease-in-out;
    }

    &--left &--open&__content {
        transform: translate(0);
    }

    &--right {
        flex: 1 0 auto;
        flex-direction: row-reverse;
    }

    &--right &__content {
        border-start-start-radius: 16px;
        border-end-start-radius: 16px;
        transform: translate(100%);
        transition: transform 300ms ease-in-out;
    }

    &--right &--open&__content {
        transform: translate(0);
    }

    &--center {
        justify-content: center;
        align-items: center;
    }

    &--center &__content {
        max-block-size: 90%;
        margin-inline: 20px;
        opacity: 0;
        transition: opacity 300ms ease-in;

        @media (max-width: @screen-xs) {
            margin-inline: 16px;
            max-block-size: none;
        }
    }

    &--center &--open&__content {
        opacity: 1;
    }

    &--xs &__content {
        flex-basis: 510px;
    }

    &--sm &__content {
        flex-basis: 640px;
    }

    &--md &__content {
        flex-basis: 900px;
    }

    &--lg &__content {
        flex-basis: 1200px;
    }

    @media (min-width: 850px) {
        &--side&--xs &__content {
            flex-basis: 850px;
        }

        &--side&--xs &__side {
            display: flex;
        }
    }

    @media (max-width: 850px) {
        &--side&--xs &__side {
            display: none;
        }
    }

    @media (min-width: 1000px) {
        &--side&--sm &__content {
            flex-basis: 1000px;
        }

        &--side&--sm &__side {
            display: flex;
        }
    }

    @media (min-width: 1240px) {
        &--side&--md &__content {
            flex-basis: 1240px;
        }

        &--side&--md &__side {
            display: flex;
        }
    }

    @media (min-width: 1540px) {
        &--side&--lg &__content {
            flex-basis: 1540px;
        }

        &--side&--lg &__side {
            display: flex;
        }
    }

    &__backdrop {
        position: fixed;
        inset-block: 0;
        inset-inline: 0;
        opacity: 0;
        background-color: rgb(34 34 34 / 0.7);
        overflow: auto;
        user-select: none;
        transition: opacity 300ms linear;
    }

    &--open&__backdrop {
        opacity: 1;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;