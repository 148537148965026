// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

/*
Markdown

Markup:
<div class="markdown {{.modifier}}">{{children}}</div>

<div class="markdown--compact">
    <div class="markdown">{{children}}</div>
</div>

Modifiers:
.markdown--compact
*/

@markdown--prefix-cls: ~"@{cls-prefix}markdown";

.@{markdown--prefix-cls} {
    h1,
    h2,
    h3,
    h4,
    h5 {
        line-height: 1.1;
        font-weight: 700;
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 16px;
    }

    h4,
    h5 {
        font-size: 14px;
    }

    img {
        max-inline-size: 100%;
    }

    &--compact,
    &--compact & {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ul,
        ol {
            margin: inherit;
            line-height: inherit;
            font-weight: inherit;
            color: inherit;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;