// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Icon/variables";
@import "../Spinner/variables";
@import "../../helpers/mixins";

.@{button--prefix-cls} {
    display: inline-block;
    overflow: visible;
    box-sizing: border-box;
    min-block-size: @button--height;
    min-inline-size: 70px;
    margin: 0;
    padding-block: @button--padding-vertical;
    padding-inline: @button--padding-horizontal;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
    text-transform: none;
    border: none;
    border-radius: 2px;
    font: inherit;
    font-size: @button--font-size;
    font-weight: @button--font-weight;
    line-height: @button--line-height;
    color: var(--pul-button--color, var(--pul-color-text));
    background-color: var(--pul-button--bg-color, var(--pul-color-bg-secondary));
    box-shadow: @button--shadow;
    transition: background-color linear 0.2s, box-shadow linear 0.2s, opacity linear 0.2s;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &--arrow {
        position: relative;
        margin-inline-end: 8px;
    }

    &--arrow-backward {
        margin-inline: 8px 0;
    }

    &__arrow {
        position: absolute;
        inline-size: 12px;
        block-size: @button--height;
        inset-block-start: 0;
        inset-inline-end: -11px;
        overflow: hidden;

        &::before {
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 1px;
            content: "";
            inline-size: 24px;
            block-size: 15px;
            border-radius: 2px;
            transform: rotate(45deg) translateX(-50%) skew(18deg, 18deg);
            transform-origin: 0 50%;
            background-color: var(--pul-button--bg-color, var(--pul-color-bg-secondary));
            box-shadow: @button--arrow-arrow-shadow;
            transition: background-color linear 0.2s, box-shadow linear 0.2s;
        }
    }

    &--arrow-backward &__arrow {
        inset-inline-start: -11px;
        transform: scaleX(-1);
    }

    &--arrow&--lg {
        margin-inline-end: 10px;
    }

    &--arrow-backward&--lg {
        margin-inline: 10px 0;
    }

    &--arrow&--lg &__arrow {
        inline-size: 14px;
        block-size: @button--height-large;
        inset-inline-end: -13px;

        &::before {
            inset-block-start: 0;
            inset-inline-start: 1px;
            inline-size: 32px;
            block-size: 24px;
            border-radius: 2px;
            transform: rotate(45deg) translateX(-50%) skew(20deg, 20deg);
        }
    }

    &--arrow-backward&--lg &__arrow {
        inset-inline-start: -13px;
    }

    &:hover,
    &:focus,
    &.@{button--prefix-cls}--focused,
    &.@{button--prefix-cls}--hovered {
        text-decoration: none;
        color: var(--pul-button--color-hover, var(--pul-color-text));
        background-color: var(--pul-button--bg-color-hover, var(--pul-color-bg-secondary-hover));
        box-shadow: @button--shadow-hover;

        .@{button--prefix-cls}__arrow::before {
            background-color: var(
                --pul-button--bg-color-hover,
                var(--pul-color-bg-secondary-hover)
            );
            box-shadow: @button--arrow-arrow-shadow-hover;
        }
    }

    &:focus-visible,
    &:has(input:focus-visible) {
        box-shadow: @button--shadow-focus;
        outline: none;
    }

    &--arrow:focus-visible {
        box-shadow: @button--arrow-shadow-focus;

        .@{button--prefix-cls}__arrow::before {
            box-shadow: @button--arrow-arrow-shadow-focus;
        }
    }

    &:active,
    &.@{button--prefix-cls}--active {
        color: var(--pul-button--color-active, var(--pul-color-text));
        background-color: var(--pul-button--bg-color-active, var(--pul-color-bg-secondary-pressed));
        box-shadow: @button--shadow-active;

        .@{button--prefix-cls}__arrow::before {
            background-color: var(
                --pul-button--bg-color-active,
                var(--pul-color-bg-secondary-pressed)
            );
            box-shadow: @button--arrow-arrow-shadow-active;
        }
    }

    &[disabled],
    &--disabled,
    &--loading {
        &,
        &:hover,
        &.@{button--prefix-cls}--hovered,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &:active {
            cursor: not-allowed;
            opacity: 0.5;
            color: var(--pul-button--color, var(--pul-color-text));
            background-color: var(--pul-button--bg-color, var(--pul-color-bg-secondary));
            box-shadow: @button--shadow;

            .@{button--prefix-cls}__arrow::before {
                background-color: var(--pul-button--bg-color, var(--pul-color-bg-secondary));
                box-shadow: @button--arrow-arrow-shadow;
            }
        }
    }

    &--selected {
        color: var(--pul-button--color-active, var(--pul-color-text));
        box-shadow: @button--shadow-active;
        background-color: var(--pul-button--bg-color-active, var(--pul-color-bg-secondary-pressed));

        &:hover,
        &.@{button--prefix-cls}--hovered {
            background-color: var(
                --pul-button--bg-color-active,
                var(--pul-color-bg-secondary-pressed)
            );
            box-shadow: @button--shadow-active;
        }

        &:focus-visible {
            background-color: var(
                --pul-button--bg-color-active,
                var(--pul-color-bg-secondary-pressed)
            );
            box-shadow: @button--shadow-active-focus;
        }
    }

    &--primary {
        --pul-button--color: var(--pul-button--primary-color, var(--pul-color-text-on-dark));
        --pul-button--color-hover: var(
            --pul-button--primary-color-hover,
            var(--pul-color-text-on-dark)
        );
        --pul-button--color-active: var(
            --pul-button--primary-color-active,
            var(--pul-color-text-on-dark)
        );
        --pul-button--bg-color: var(--pul-button--primary-bg-color, var(--pul-color-bg-primary));
        --pul-button--bg-color-hover: var(
            --pul-button--primary-bg-color-hover,
            var(--pul-color-bg-primary-hover)
        );
        --pul-button--bg-color-active: var(
            --pul-button--primary-bg-color-active,
            var(--pul-color-bg-primary-pressed)
        );
    }

    &--success {
        --pul-button--color: var(--pul-button--success-color, var(--pul-color-text-on-dark));
        --pul-button--color-hover: var(
            --pul-button--success-color-hover,
            var(--pul-color-text-on-dark)
        );
        --pul-button--color-active: var(
            --pul-button--success-color-active,
            var(--pul-color-text-on-dark)
        );
        --pul-button--bg-color: var(--pul-button--success-bg-color, var(--pul-color-bg-success));
        --pul-button--bg-color-hover: var(
            --pul-button--success-bg-color-hover,
            var(--pul-color-bg-success-hover)
        );
        --pul-button--bg-color-active: var(
            --pul-button--success-bg-color-active,
            var(--pul-color-bg-success-pressed)
        );
    }

    &--warning {
        --pul-button--color: var(--pul-button--warning-color, var(--pul-color-text-on-dark));
        --pul-button--color-hover: var(
            --pul-button--warning-color-hover,
            var(--pul-color-text-on-dark)
        );
        --pul-button--color-active: var(
            --pul-button--warning-color-active,
            var(--pul-color-text-on-dark)
        );
        --pul-button--bg-color: var(--pul-button--warning-bg-color, var(--pul-color-bg-warning));
        --pul-button--bg-color-hover: var(
            --pul-button--warning-bg-color-hover,
            var(--pul-color-bg-warning-hover)
        );
        --pul-button--bg-color-active: var(
            --pul-button--warning-bg-color-active,
            var(--pul-color-bg-warning-pressed)
        );
    }

    &--danger {
        --pul-button--color: var(--pul-button--danger-color, var(--pul-color-text-on-dark));
        --pul-button--color-hover: var(
            --pul-button--danger-color-hover,
            var(--pul-color-text-on-dark)
        );
        --pul-button--color-active: var(
            --pul-button--danger-color-active,
            var(--pul-color-text-on-dark)
        );
        --pul-button--bg-color: var(--pul-button--danger-bg-color, var(--pul-color-bg-danger));
        --pul-button--bg-color-hover: var(
            --pul-button--danger-bg-color-hover,
            var(--pul-color-bg-danger-hover)
        );
        --pul-button--bg-color-active: var(
            --pul-button--danger-bg-color-active,
            var(--pul-color-bg-danger-pressed)
        );
    }

    &--loading {
        position: relative;
        cursor: default;
        pointer-events: none;

        .@{button--prefix-cls}__spinner {
            inline-size: 12px;
            block-size: 12px;
            margin-inline: -4px 4px;
            vertical-align: top;
        }

        &.@{button--prefix-cls}--empty .@{button--prefix-cls}__spinner {
            inline-size: 16px;
            block-size: 16px;
        }

        .@{button--prefix-cls}__icon {
            display: none;
        }
    }

    &__icon {
        flex: none;
        margin-inline: -8px 6px;
        vertical-align: top;
    }

    &__caret {
        vertical-align: 0;
        margin-inline: 2px -12px;
    }

    &__icon:not(.@{icon--prefix-cls}--intent),
    &__caret {
        color: var(--pul-button--icon-color, var(--pul-color-icon));
    }

    &--caret,
    &--empty {
        min-inline-size: 28px;
    }

    &--empty {
        padding: @button--padding-vertical;

        .@{button--prefix-cls}__icon,
        .@{button--prefix-cls}__spinner,
        .@{icon--prefix-cls} {
            margin: 0;
        }

        &.@{button--prefix-cls}--loading::before {
            margin-inline-end: 0;
        }

        .@{button--prefix-cls}__caret {
            margin-inline: 0;
        }

        .@{button--prefix-cls}__icon ~ .@{button--prefix-cls}__caret,
        .@{button--prefix-cls}__spinner ~ .@{button--prefix-cls}__caret {
            margin-inline: 2px -3px;
        }
    }

    &--empty&--caret {
        padding-block: @button--padding-vertical;
        padding-inline: 8px;
    }

    &--empty&--ghost {
        padding-inline: 6px;
    }

    &--fill {
        display: block;
        inline-size: 100%;
    }

    &--primary,
    &--success,
    &--warning,
    &--danger {
        .@{button--prefix-cls}__icon,
        .@{button--prefix-cls}__caret {
            color: currentcolor;
        }

        &.@{button--prefix-cls}--loading .@{spinner--prefix-cls}__path {
            stroke: currentcolor;
            animation: @spinner--animation-dash @spinner--animation-duration ease-in-out infinite;
        }
    }

    &--ghost {
        --pul-button--color: var(--pul-button--ghost-color, var(--pul-color-text-link));
        --pul-button--color-hover: var(
            --pul-button--ghost-bg-color-hover,
            var(--pul-color-text-link)
        );
        --pul-button--color-active: var(
            --pul-button--ghost-bg-color-active,
            var(--pul-color-text-link)
        );
        --pul-button--bg-color: var(--pul-button--ghost-bg-color, transparent);
        --pul-button--bg-color-hover: var(
            --pul-button--ghost-bg-color-hover,
            var(--pul-color-bg-secondary-hover-subtler)
        );
        --pul-button--bg-color-active: var(
            --pul-button--ghost-bg-color-active,
            var(--pul-color-bg-secondary-pressed-subtler)
        );

        padding-inline: 8px;
        min-inline-size: 28px;
        box-shadow: none;

        &:hover,
        &:focus,
        &.@{button--prefix-cls}--focused,
        &.@{button--prefix-cls}--hovered,
        &:active,
        &.@{button--prefix-cls}--active,
        &.@{button--prefix-cls}--selected {
            box-shadow: none;
        }

        &:focus-visible {
            box-shadow: @button--ghost-shadow-focus;

            &[disabled],
            &.@{button--prefix-cls}--disabled,
            &.@{button--prefix-cls}--loading {
                box-shadow: none;
            }
        }

        .@{button--prefix-cls}__icon {
            margin-inline-start: 0;
            opacity: 1;
        }

        .@{button--prefix-cls}__caret {
            margin-inline-end: 0;
        }
    }

    &--lg {
        min-block-size: @button--height-large;
        min-inline-size: 100px;
        padding-block: @button--padding-vertical-large;
        padding-inline: @button--padding-horizontal-large;
        font-size: @button--font-size-large;

        .@{button--prefix-cls}__icon {
            margin-inline: -8px 8px;
        }

        .@{button--prefix-cls}__caret {
            margin-inline: 4px -12px;
        }

        &.@{button--prefix-cls}--caret,
        &.@{button--prefix-cls}--empty {
            min-inline-size: 40px;
        }
    }

    &--lg&--empty {
        padding: @button--padding-vertical-large;

        .@{button--prefix-cls}__icon,
        .@{button--prefix-cls}__spinner,
        .@{icon--prefix-cls} {
            margin: 0;
        }

        &.@{button--prefix-cls}--loading::before {
            margin-inline-end: 0;
        }

        .@{button--prefix-cls}__caret {
            margin-inline: 0;
        }

        .@{button--prefix-cls}__icon ~ .@{button--prefix-cls}__caret,
        .@{button--prefix-cls}__spinner ~ .@{button--prefix-cls}__caret {
            margin-inline: 4px -3px;
        }

        &.@{button--prefix-cls}--caret {
            padding-block: @button--padding-vertical-large;
            padding-inline: 12px;
        }

        &.@{button--prefix-cls}--ghost {
            padding-inline: 10px;
        }
    }
}

// stylelint-disable-next-line no-duplicate-selectors
.@{button--prefix-cls} {
    // on-dark
    &--on-dark:focus-visible,
    &--on-dark:has(input:focus-visible) {
        box-shadow: @button--on-dark-shadow-focus;
    }

    &--on-dark&--arrow:focus-visible {
        box-shadow: @button--on-dark-arrow-shadow-focus;

        .@{button--prefix-cls}__arrow::before {
            box-shadow: @button--on-dark-arrow-arrow-shadow-focus;
        }
    }

    &--on-dark&--ghost {
        --pul-button--color: var(
            --pul-button--ghost-color-on-dark,
            var(--pul-color-text-link-on-dark)
        );
        --pul-button--color-hover: var(
            --pul-button--ghost-color-hover-on-dark,
            var(--pul-color-text-link-on-dark)
        );
        --pul-button--color-active: var(
            --pul-button--ghost-color-active-on-dark,
            var(--pul-color-text-link-on-dark)
        );
        --pul-button--bg-color-hover: var(
            --pul-button--ghost-bg-color-hover-on-dark,
            var(--pul-color-bg-secondary-hover-on-dark-subtler)
        );
        --pul-button--bg-color-active: var(
            --pul-button--ghost-bg-color-active-on-dark,
            var(--pul-color-bg-secondary-pressed-on-dark-subtler)
        );
        --pul-button--icon-color: var(
            --pul-button--icon-color-on-dark,
            var(--pul-color-icon-on-dark-subtler)
        );

        &:focus-visible {
            box-shadow: @button--on-dark-ghost-shadow-focus;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;