// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../../helpers/variables";

.@{text--prefix-cls} {
    &--truncate {
        display: inline-block;
        max-inline-size: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: top;
        line-height: inherit;
    }

    &--nowrap {
        white-space: nowrap;
    }

    &--success {
        color: @text--success-color;
    }

    &--info {
        color: @text--info-color;
    }

    &--warning {
        color: @text--warning-color;
    }

    &--danger {
        color: @text--danger-color;
    }

    &--muted {
        color: @text--muted-color;
    }

    &--on-dark&--success {
        color: @text--on-dark-success-color;
    }

    &--on-dark&--info {
        color: @text--on-dark-info-color;
    }

    &--on-dark&--warning {
        color: @text--on-dark-warning-color;
    }

    &--on-dark&--danger {
        color: @text--on-dark-danger-color;
    }

    &--on-dark&--muted {
        color: @text--on-dark-muted-color;
    }

    &--bold {
        font-weight: 600;
    }

    &--italic {
        font-style: italic;
    }

    &--sm {
        font-size: @text--font-size-sm;
    }

    &--md {
        font-size: @text--font-size-md;
    }

    &--mark {
        color: @text--mark-color;
        background-color: @text--mark-background-color;
    }

    &--on-dark&--mark {
        color: @text--mark-on-dark-color;
    }

    &--mark,
    &--on-dark&--mark {
        a > & {
            color: inherit;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;