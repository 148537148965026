// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "./variables.less";

.generate-grid(@class, @index: 2) when (@index =< 6) {
    .@{grid--prefix-cls}--@{class}-@{index} > .@{grid--prefix-cls}__col {
        flex-basis: (1 / @index * 100%);
        max-inline-size: (1 / @index * 100%);
    }
    .@{grid--prefix-cls}--@{class}-auto > .@{grid--prefix-cls}__col {
        flex-basis: 0;
    }
    .generate-grid(@class, @index + 1);
}
.generate-column(@class, @index: 1) when (@index =< @grid--columns) {
    .@{grid--prefix-cls}__col--@{class}-@{index} {
        // stylelint-disable-next-line declaration-no-important
        flex-basis: percentage((@index / @grid--columns)) !important;
        // stylelint-disable-next-line declaration-no-important
        max-inline-size: percentage((@index / @grid--columns)) !important;
    }
    .generate-column(@class, @index + 1);
}

.@{grid--prefix-cls} {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    &__col {
        flex: 1 1 100%;
        box-sizing: border-box;
        max-inline-size: 100%;
        padding: 0;

        > :last-child {
            margin-block-end: 0;
        }
    }

    &&__col {
        margin: 0;
        padding: 0;
    }
}

@media (min-width: @screen-xs) {
    .generate-grid(xs);
    .generate-column(xs);
}

@media (min-width: @screen-sm) {
    .generate-grid(sm);
    .generate-column(sm);
}

@media (min-width: @screen-md) {
    .generate-grid(md);
    .generate-column(md);
}

@media (min-width: @screen-lg) {
    .generate-grid(lg);
    .generate-column(lg);
}

@media (min-width: @screen-xl) {
    .generate-grid(xl);
    .generate-column(xl);
}

.@{grid--prefix-cls}--gap-xs {
    margin-inline-start: -@grid--gap-xs;

    > .@{grid--prefix-cls}__col {
        padding-inline-start: @grid--gap-xs;
        padding-block-end: @grid--gap-xs;

        > .@{grid--prefix-cls} {
            margin-block-end: -@grid--gap-xs;
        }
    }
}

.@{grid--prefix-cls}--gap-sm {
    margin-inline-start: -@grid--gap-sm;

    > .@{grid--prefix-cls}__col {
        padding-inline-start: @grid--gap-sm;
        padding-block-end: @grid--gap-sm;

        > .@{grid--prefix-cls} {
            margin-block-end: -@grid--gap-sm;
        }
    }
}

.@{grid--prefix-cls}--gap-md {
    margin-inline-start: -@grid--gap-md;

    > .@{grid--prefix-cls}__col {
        padding-inline-start: @grid--gap-md;
        padding-block-end: @grid--gap-md;

        > .@{grid--prefix-cls} {
            margin-block-end: -@grid--gap-md;
        }
    }
}

.@{grid--prefix-cls}--gap-lg {
    margin-inline-start: -@grid--gap-lg;

    > .@{grid--prefix-cls}__col {
        padding-inline-start: @grid--gap-lg;
        padding-block-end: @grid--gap-lg;

        > .@{grid--prefix-cls} {
            margin-block-end: -@grid--gap-lg;
        }
    }
}

.@{grid--prefix-cls}--gap-xl {
    margin-inline-start: -@grid--gap-xl;

    > .@{grid--prefix-cls}__col {
        padding-inline-start: @grid--gap-xl;
        padding-block-end: @grid--gap-xl;

        > .@{grid--prefix-cls} {
            margin-block-end: -@grid--gap-xl;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;