// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "variables";

.@{skeleton--prefix-cls} {
    display: inline-flex;
    inline-size: 100%;
    position: relative;
    overflow: hidden;
    background-color: @skeleton--background-color;
    border-radius: 0.25rem;
    z-index: 1;

    &::before {
        content: "\00a0";
    }

    &::after {
        content: " ";
        display: block;
        position: absolute;
        inset-inline: 0;
        block-size: 100%;
        background-repeat: no-repeat;
        background-image: linear-gradient(
            90deg,
            transparent,
            @skeleton--background-highlight-color,
            transparent
        );
        transform: translateX(-100%);
        animation: @skeleton--animation 1.5s ease-in-out infinite;
    }
}

@keyframes @skeleton--animation {
    100% {
        transform: translateX(100%);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;