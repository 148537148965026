// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "variables";
@import "../Input/variables";
@import "../Checkbox/variables";
@import "../Radio/variables";
@import "../Section/variables";

.@{form-field--prefix-cls} {
    box-sizing: border-box;
    min-block-size: 28px;

    &--error {
        position: relative;

        .@{input--prefix-cls}__input:not(:focus):not(:disabled) {
            border-color: @form-field--border-color-error;
            background-color: @form-field--background-color-error;
        }

        .@{input--prefix-cls}__input:not(:focus):not(:disabled):hover {
            border-color: @form-field--border-color-hover-error;
            background-color: @form-field--background-color-error;
        }

        .@{form-field--prefix-cls}:not(.@{form-field--prefix-cls}--error) {
            .@{input--prefix-cls}__input:not(:focus):not(:disabled) {
                border-color: @input--border-color;
                background-color: @input--background-color;
            }

            .@{input--prefix-cls}__input:not(:focus):not(:disabled):hover {
                border-color: @input--border-color-hover;
            }
        }

        .@{checkbox--prefix-cls}__input:not(:focus):not(:disabled)
            + .@{checkbox--prefix-cls}__indicator,
        .@{radio--prefix-cls}__input:not(:focus):not(:disabled) + .@{radio--prefix-cls}__indicator {
            border-color: @form-field--border-color-error;
            background-color: @form-field--background-color-error;
        }

        .@{checkbox--prefix-cls}:hover
            .@{checkbox--prefix-cls}__input:not(:focus):not(:disabled)
            + .@{checkbox--prefix-cls}__indicator,
        .@{radio--prefix-cls}:hover
            .@{radio--prefix-cls}__input:not(:focus):not(:disabled)
            + .@{radio--prefix-cls}__indicator {
            border-color: @form-field--border-color-hover-error;
        }

        .@{checkbox--prefix-cls}__input:checked:not(:focus):not(:disabled)
            + .@{checkbox--prefix-cls}__indicator,
        .@{radio--prefix-cls}__input:checked:not(:focus):not(:disabled)
            + .@{radio--prefix-cls}__indicator {
            border-color: @form-field--border-color-error;
            background-color: @form-field--border-color-error;
        }
    }

    &__error,
    &__description {
        display: block;
        max-inline-size: 400px;
        margin-block: 4px 0;
        margin-inline: 0;
        padding: 0;
        font-size: 12px;
        line-height: normal;
    }

    &__label {
        position: relative;
        display: inline;
    }

    &__error {
        color: @token-color-danger-2;
    }

    &__error-icon {
        vertical-align: top;
        margin-block-start: 2px;
        margin-inline-end: 8px;
    }

    &__add-more {
        display: inline-block;
        margin-block-start: 8px;
    }

    &__separator {
        inline-size: 8px;
        display: inline-flex;
    }

    &--vertical-multi:not(:last-child) {
        margin-block-end: 0;
    }

    &__full-description,
    &__full-description-icon {
        vertical-align: top;
    }

    &__full-description {
        margin-block-start: 4px;
        margin-inline-start: 2px;
        line-height: 12px;
    }

    .@{section-item--prefix-cls}--vertical &__full-description {
        margin-block-start: 2px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;