// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "variables";

/*
Alert

Markup:
<div class="alert .modifier">
  {{children}}
</div>

Modifiers:
.alert--success
.alert--warning
.alert--danger
.alert--info
*/

.@{alert--prefix-cls} {
    padding-block: 10px;
    padding-inline: 16px;
    margin-block-end: @base-margin-vertical;
    background-color: @alert--background;
    border-radius: 2px;
    overflow-wrap: break-word;

    * + & {
        margin-block-start: @base-margin-vertical;
    }

    .close {
        text-shadow: none;
        float: inline-end;
        line-height: 20px;
        margin-inline-start: 10px;
        text-align: center;
        cursor: pointer;
        color: @alert--close-color;

        &:hover {
            color: @base-link-hover-color;
        }
    }

    .close::before {
        content: "\2715";
        font-weight: 600;
        font-size: 12px;
    }

    .btn {
        margin: 0;
        vertical-align: inherit;
    }

    &,
    .b-indent {
        > p,
        > ul {
            margin-block-end: 0;
        }

        > p + p,
        > p + .b-indent {
            margin-block-start: 8px;
        }
    }

    .b-indent {
        + .b-indent,
        + p {
            margin-block-start: 8px;
        }
    }

    // Intent

    &--success {
        background-color: @alert--success-background;

        hr {
            border-block-start-color: darken(@alert--success-background, 10%);
        }
    }

    &--info {
        background-color: @alert--info-background;

        hr {
            border-block-start-color: darken(@alert--info-background, 10%);
        }
    }

    &--warning {
        background-color: @alert--warning-background;

        hr {
            border-block-start-color: darken(@alert--warning-background, 10%);
        }
    }

    &--danger {
        background-color: @alert--danger-background;

        hr {
            border-block-start-color: darken(@alert--danger-background, 10%);
        }
    }

    .panel & {
        padding-block: 12px;
        padding-inline: 16px;
        margin-inline: -16px;

        .close::before {
            font-size: 12px;
        }

        .btn {
            min-block-size: 0;
            padding-block: 4px;
            padding-inline: 12px;
            font-size: inherit;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;