// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Status/variables";
@import "../Icon/variables";

.@{action--prefix-cls} {
    display: inline-block;

    &--primary &__content {
        font-weight: 600;
    }

    &--progress &__content {
        cursor: default;
    }

    &--progress a&__content {
        text-decoration: none;
        color: @base-link-color;
    }

    &--nowrap {
        white-space: nowrap;
    }

    &__icon {
        vertical-align: middle;
    }

    &__icon:not(.@{icon--prefix-cls}--intent) {
        color: @action--icon-color;
    }

    a:hover &__icon:not(.@{icon--prefix-cls}--intent) {
        color: @base-link-hover-color;
    }

    &:not(.@{action--prefix-cls}--icon):not(.@{status--prefix-cls}--compact):not(
            .@{status--prefix-cls}--flip
        ),
    &
        &:not(.@{action--prefix-cls}--icon):not(.@{status--prefix-cls}--compact):not(
            .@{status--prefix-cls}--flip
        ) {
        > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
            float: inline-start;
        }

        &.@{action--prefix-cls}--size-12 {
            padding-inline-start: @action--12;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: 4px;
                margin-inline-start: -@action--12;
            }
        }

        &.@{action--prefix-cls}--size-16 {
            padding-inline-start: @action--16;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: 2px;
                margin-inline-start: -@action--16;
            }
        }

        &.@{action--prefix-cls}--size-24 {
            padding-block-start: @action--shift-24;
            padding-inline-start: @action--24;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-24;
                margin-inline-start: -@action--24;
            }
        }

        &.@{action--prefix-cls}--size-32 {
            padding-block-start: @action--shift-32;
            padding-inline-start: @action--32;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-32;
                margin-inline-start: -@action--32;
            }
        }

        &.@{action--prefix-cls}--size-48 {
            padding-block-start: @action--shift-48;
            padding-inline-start: @action--48;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-48;
                margin-inline-start: -@action--48;
            }
        }

        &.@{action--prefix-cls}--size-64 {
            padding-block-start: @action--shift-64;
            padding-inline-start: @action--64;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-64;
                margin-inline-start: -@action--64;
            }
        }

        &.@{action--prefix-cls}--size-96 {
            padding-block-start: @action--shift-96;
            padding-inline-start: @action--96;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-96;
                margin-inline-start: -@action--96;
            }
        }

        &.@{action--prefix-cls}--size-128 {
            padding-block-start: @action--shift-128;
            padding-inline-start: @action--128;

            .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-128;
                margin-inline-start: -@action--128;
            }
        }

        &.@{action--prefix-cls}--size-192 {
            padding-block-start: @action--shift-192;
            padding-inline-start: @action--192;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-192;
                margin-inline-start: -@action--192;
            }
        }
    }

    &.@{status--prefix-cls}--flip:not(.@{action--prefix-cls}--icon):not(
            .@{status--prefix-cls}--compact
        ),
    &
        &.@{status--prefix-cls}--flip:not(.@{action--prefix-cls}--icon):not(
            .@{status--prefix-cls}--compact
        ) {
        > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
            float: inline-end;
        }

        &.@{action--prefix-cls}--size-16 {
            padding-inline-end: @action--16;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: 2px;
                margin-inline-end: -@action--16;
            }
        }

        &.@{action--prefix-cls}--size-24 {
            padding-block-start: @action--shift-24;
            padding-inline-end: @action--24;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-24;
                margin-inline-end: -@action--24;
            }
        }

        &.@{action--prefix-cls}--size-32 {
            padding-block-start: @action--shift-32;
            padding-inline-end: @action--32;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-32;
                margin-inline-end: -@action--32;
            }
        }

        &.@{action--prefix-cls}--size-48 {
            padding-block-start: @action--shift-48;
            padding-inline-end: @action--48;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-48;
                margin-inline-end: -@action--48;
            }
        }

        &.@{action--prefix-cls}--size-64 {
            padding-block-start: @action--shift-64;
            padding-inline-end: @action--64;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-64;
                margin-inline-end: -@action--64;
            }
        }

        &.@{action--prefix-cls}--size-96 {
            padding-block-start: @action--shift-96;
            padding-inline-end: @action--96;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-96;
                margin-inline-end: -@action--96;
            }
        }

        &.@{action--prefix-cls}--size-128 {
            padding-block-start: @action--shift-128;
            padding-inline-end: @action--128;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-128;
                margin-inline-end: -@action--128;
            }
        }

        &.@{action--prefix-cls}--size-192 {
            padding-block-start: @action--shift-192;
            padding-inline-end: @action--192;

            > .@{action--prefix-cls}__content > .@{action--prefix-cls}__icon {
                margin-block-start: -@action--shift-192;
                margin-inline-end: -@action--192;
            }
        }
    }

    &__right-addon {
        display: inline-block;
        margin-inline-start: 4px;
        vertical-align: 0;
    }

    &__bottom-addon {
        display: block;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;