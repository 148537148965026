// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables.less";

@progress-dialog--prefix-cls: ~"@{cls-prefix}progress-dialog";

.@{progress-dialog--prefix-cls} {
    &__description {
        margin-block: 12px 32px;
        margin-inline: auto;
        font-size: 14px;
        text-align: center;
    }

    &__content {
        margin-block-end: 8px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;