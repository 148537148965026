/* Copyright 1999-2024. Plesk International GmbH. All rights reserved. */

:where(html) {
    /* Icons */
    --pul-color-icon: var(--pul-gray-750);
    --pul-color-icon-subtle: var(--pul-gray-700);
    --pul-color-icon-subtler: var(--pul-gray-600);
    --pul-color-icon-primary: var(--pul-blue-600);
    --pul-color-icon-success: var(--pul-green-700);
    --pul-color-icon-warning: var(--pul-yellow-700);
    --pul-color-icon-danger: var(--pul-red-600);
    --pul-color-icon-info: var(--pul-blue-600);
    --pul-color-icon-on-dark: var(--pul-white);
    --pul-color-icon-on-dark-subtler: var(--pul-gray-a500);
    --pul-color-icon-success-on-dark: var(--pul-green-500);
    --pul-color-icon-warning-on-dark: var(--pul-yellow-500);
    --pul-color-icon-danger-on-dark: var(--pul-red-500);
    --pul-color-icon-info-on-dark: var(--pul-blue-500);

    /* Text */
    --pul-color-text: var(--pul-gray-800);
    --pul-color-text-subtle: var(--pul-gray-750);
    --pul-color-text-subtler: var(--pul-gray-600);
    --pul-color-text-link: var(--pul-blue-800);
    --pul-color-text-link-hover: var(--pul-blue-700);
    --pul-color-text-success: var(--pul-green-700);
    --pul-color-text-warning: var(--pul-yellow-700);
    --pul-color-text-danger: var(--pul-red-600);
    --pul-color-text-info: var(--pul-blue-800);
    --pul-color-text-on-dark: var(--pul-white);
    --pul-color-text-on-dark-subtle: var(--pul-gray-600);
    --pul-color-text-link-on-dark: var(--pul-blue-500);

    /* Border */
    --pul-color-border: var(--pul-gray-900);
    --pul-color-border-subtle: var(--pul-gray-600);
    --pul-color-border-subtler: var(--pul-gray-400);
    --pul-color-border-primary: var(--pul-blue-600);
    --pul-color-border-info: var(--pul-blue-600);
    --pul-color-border-success: var(--pul-green-700);
    --pul-color-border-warning: var(--pul-yellow-700);
    --pul-color-border-danger: var(--pul-red-600);
    --pul-color-border-focus: var(--pul-blue-a400);

    /* Background */
    --pul-color-bg-secondary: var(--pul-gray-300);
    --pul-color-bg-secondary-hover: var(--pul-gray-400);
    --pul-color-bg-secondary-pressed: var(--pul-gray-500);
    --pul-color-bg-secondary-hover-subtle: var(--pul-gray-a700);
    --pul-color-bg-secondary-pressed-subtle: var(--pul-gray-a800);
    --pul-color-bg-secondary-hover-subtler: var(--pul-gray-a600);
    --pul-color-bg-secondary-pressed-subtler: var(--pul-gray-a700);
    --pul-color-bg-secondary-hover-on-dark-subtler: var(--pul-gray-a100);
    --pul-color-bg-secondary-pressed-on-dark-subtler: var(--pul-gray-a200);
    --pul-color-bg-primary: var(--pul-blue-600);
    --pul-color-bg-primary-hover: var(--pul-blue-700);
    --pul-color-bg-primary-pressed: var(--pul-blue-800);
    --pul-color-bg-primary-subtle: var(--pul-blue-200);
    --pul-color-bg-primary-hover-subtle: var(--pul-blue-300);
    --pul-color-bg-primary-pressed-subtle: var(--pul-blue-400);
    --pul-color-bg-primary-hover-subtler: var(--pul-blue-100);
    --pul-color-bg-primary-pressed-subtler: var(--pul-blue-200);
    --pul-color-bg-neutral: var(--pul-gray-700);
    --pul-color-bg-neutral-hover: var(--pul-gray-750);
    --pul-color-bg-neutral-pressed: var(--pul-gray-a900);
    --pul-color-bg-neutral-subtle: var(--pul-gray-300);
    --pul-color-bg-neutral-hover-subtle: var(--pul-gray-400);
    --pul-color-bg-neutral-pressed-subtle: var(--pul-gray-500);
    --pul-color-bg-neutral-hover-subtler: var(--pul-gray-200);
    --pul-color-bg-neutral-pressed-subtler: var(--pul-gray-300);
    --pul-color-bg-info: var(--pul-blue-600);
    --pul-color-bg-info-hover: var(--pul-blue-700);
    --pul-color-bg-info-pressed: var(--pul-blue-800);
    --pul-color-bg-info-subtle: var(--pul-blue-200);
    --pul-color-bg-info-hover-subtle: var(--pul-blue-300);
    --pul-color-bg-info-pressed-subtle: var(--pul-blue-400);
    --pul-color-bg-info-hover-subtler: var(--pul-blue-100);
    --pul-color-bg-info-pressed-subtler: var(--pul-blue-200);
    --pul-color-bg-success: var(--pul-green-700);
    --pul-color-bg-success-hover: var(--pul-green-800);
    --pul-color-bg-success-pressed: var(--pul-green-900);
    --pul-color-bg-success-subtle: var(--pul-green-200);
    --pul-color-bg-success-hover-subtle: var(--pul-green-300);
    --pul-color-bg-success-pressed-subtle: var(--pul-green-400);
    --pul-color-bg-success-hover-subtler: var(--pul-green-100);
    --pul-color-bg-success-pressed-subtler: var(--pul-green-200);
    --pul-color-bg-warning: var(--pul-yellow-700);
    --pul-color-bg-warning-hover: var(--pul-yellow-800);
    --pul-color-bg-warning-pressed: var(--pul-yellow-900);
    --pul-color-bg-warning-subtle: var(--pul-yellow-200);
    --pul-color-bg-warning-hover-subtle: var(--pul-yellow-300);
    --pul-color-bg-warning-pressed-subtle: var(--pul-yellow-400);
    --pul-color-bg-warning-hover-subtler: var(--pul-yellow-100);
    --pul-color-bg-warning-pressed-subtler: var(--pul-yellow-200);
    --pul-color-bg-danger: var(--pul-red-600);
    --pul-color-bg-danger-hover: var(--pul-red-700);
    --pul-color-bg-danger-pressed: var(--pul-red-800);
    --pul-color-bg-danger-subtle: var(--pul-red-200);
    --pul-color-bg-danger-hover-subtle: var(--pul-red-300);
    --pul-color-bg-danger-pressed-subtle: var(--pul-red-400);
    --pul-color-bg-danger-hover-subtler: var(--pul-red-100);
    --pul-color-bg-danger-pressed-subtler: var(--pul-red-200);

    /* Background Input */
    --pul-color-bg-input: var(--pul-white);
    --pul-color-bg-input-active: var(--pul-blue-100);
    --pul-color-bg-input-danger: var(--pul-red-200);

    /* Background Layout */
    --pul-color-bg-layout: var(--pul-gray-100);
    --pul-color-bg-sidebar: var(--pul-slate-800);
    --pul-color-bg-sidebar-selected: var(--pul-slate-700);
    --pul-color-bg-sidebar-section: var(--pul-slate-900);

    /* Background Panel */
    --pul-color-bg-panel: var(--pul-white);
    --pul-color-bg-panel-hover: var(--pul-blue-100);
    --pul-color-bg-darkpanel: var(--pul-gray-800);
    --pul-color-bg-darkpanel-header: var(--pul-slate-900);
}
