/* Copyright 1999-2024. Plesk International GmbH. All rights reserved. */

:where(html) {
    /* Palette */
    --pul-black: #000;
    --pul-white: #fff;

    /* Gray */
    --pul-gray-100: #fbfbfb;
    --pul-gray-200: #f5f5f5;
    --pul-gray-300: #ececec;
    --pul-gray-400: #dedede;
    --pul-gray-500: #ccc;
    --pul-gray-600: #b5b5b5;
    --pul-gray-700: #979797;
    --pul-gray-750: #737373;
    --pul-gray-800: #222;
    --pul-gray-850: #1d1d1d;
    --pul-gray-900: #181818;

    /* Gray alpha */
    --pul-gray-a100: rgb(237 237 237 / 0.1);
    --pul-gray-a200: rgb(237 237 237 / 0.2);
    --pul-gray-a300: rgb(237 237 237 / 0.3);
    --pul-gray-a400: rgb(237 237 237 / 0.4);
    --pul-gray-a500: rgb(237 237 237 / 0.5);
    --pul-gray-a600: rgb(36 36 36 / 0.04);
    --pul-gray-a700: rgb(36 36 36 / 0.1);
    --pul-gray-a800: rgb(36 36 36 / 0.25);
    --pul-gray-a900: rgb(36 36 36 / 0.7);

    /* Slate */
    --pul-slate-100: #f7fafe;
    --pul-slate-200: #ddebf7;
    --pul-slate-300: #b4cfe6;
    --pul-slate-400: #93b1c9;
    --pul-slate-500: #7d99b0;
    --pul-slate-600: #678196;
    --pul-slate-700: #536a7d;
    --pul-slate-800: #384859;
    --pul-slate-900: #2d3a47;

    /* Blue */
    --pul-blue-100: #f0faff;
    --pul-blue-200: #d6f3ff;
    --pul-blue-300: #b4e9ff;
    --pul-blue-400: #a0e4ff;
    --pul-blue-500: #4cccff;
    --pul-blue-600: #28aade;
    --pul-blue-700: #259ccc;
    --pul-blue-800: #16759a;
    --pul-blue-900: #005678;

    /* Blue alpha */
    --pul-blue-a100: rgb(40 170 222 / 0.1);
    --pul-blue-a200: rgb(40 170 222 / 0.2);
    --pul-blue-a300: rgb(40 170 222 / 0.3);
    --pul-blue-a400: rgb(40 170 222 / 0.4);
    --pul-blue-a500: rgb(40 170 222 / 0.5);
    --pul-blue-a600: rgb(40 170 222 / 0.6);
    --pul-blue-a700: rgb(40 170 222 / 0.7);
    --pul-blue-a800: rgb(40 170 222 / 0.8);
    --pul-blue-a900: rgb(40 170 222 / 0.9);

    /* Green */
    --pul-green-100: #ecfae3;
    --pul-green-200: #ddf5ce;
    --pul-green-300: #b6ea76;
    --pul-green-400: #aee545;
    --pul-green-500: #9cd13a;
    --pul-green-600: #80af26;
    --pul-green-700: #629a1e;
    --pul-green-800: #487f1e;
    --pul-green-900: #366807;

    /* Green alpha */
    --pul-green-a100: rgb(128 175 38 / 0.1);
    --pul-green-a200: rgb(128 175 38 / 0.2);
    --pul-green-a300: rgb(128 175 38 / 0.3);
    --pul-green-a400: rgb(128 175 38 / 0.4);
    --pul-green-a500: rgb(128 175 38 / 0.5);
    --pul-green-a600: rgb(128 175 38 / 0.6);
    --pul-green-a700: rgb(128 175 38 / 0.7);
    --pul-green-a800: rgb(128 175 38 / 0.8);
    --pul-green-a900: rgb(128 175 38 / 0.9);

    /* Yellow */
    --pul-yellow-100: #fceed7;
    --pul-yellow-200: #ffeed4;
    --pul-yellow-300: #ffd68c;
    --pul-yellow-400: #ffc65f;
    --pul-yellow-500: #ffb633;
    --pul-yellow-600: #ffa04d;
    --pul-yellow-700: #d66b0d;
    --pul-yellow-800: #c84a0b;
    --pul-yellow-900: #930;

    /* Yellow alpha */
    --pul-yellow-a100: rgb(255 160 77 / 0.1);
    --pul-yellow-a200: rgb(255 160 77 / 0.2);
    --pul-yellow-a300: rgb(255 160 77 / 0.3);
    --pul-yellow-a400: rgb(255 160 77 / 0.4);
    --pul-yellow-a500: rgb(255 160 77 / 0.5);
    --pul-yellow-a600: rgb(255 160 77 / 0.6);
    --pul-yellow-a700: rgb(255 160 77 / 0.7);
    --pul-yellow-a800: rgb(255 160 77 / 0.8);
    --pul-yellow-a900: rgb(255 160 77 / 0.9);

    /* Red */
    --pul-red-100: #fff0f1;
    --pul-red-200: #ffdee0;
    --pul-red-300: #ffc7ca;
    --pul-red-400: #ff8da2;
    --pul-red-500: #f24263;
    --pul-red-600: #d02d4b;
    --pul-red-700: #b30122;
    --pul-red-800: #a80022;
    --pul-red-900: #7b0319;

    /* Red alpha */
    --pul-red-a100: rgb(208 45 75 / 0.1);
    --pul-red-a200: rgb(208 45 75 / 0.2);
    --pul-red-a300: rgb(208 45 75 / 0.3);
    --pul-red-a400: rgb(208 45 75 / 0.4);
    --pul-red-a500: rgb(208 45 75 / 0.5);
    --pul-red-a600: rgb(208 45 75 / 0.6);
    --pul-red-a700: rgb(208 45 75 / 0.7);
    --pul-red-a800: rgb(208 45 75 / 0.8);
    --pul-red-a900: rgb(208 45 75 / 0.9);
}
