// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Action/variables";
@import "../Status/variables";

@hint--prefix-cls: ~"@{cls-prefix}hint";
@hint--color: @text-muted-color;
@hint--font-size: 12px;
@hint--link-color: @base-link-color;
@hint--link-color-hover: @base-link-hover-color;

.@{hint--prefix-cls} {
    color: @hint--color;
    font-size: @hint--font-size;

    a {
        color: @hint--link-color;
    }

    a:hover {
        color: @hint--link-color-hover;
    }

    > p {
        margin-block-end: 8px;
    }

    > :last-child {
        margin-block-end: 0;
    }

    .@{status--prefix-cls} {
        color: inherit;
    }

    .@{action--prefix-cls}:not(.@{action--prefix-cls}--icon):not(
            .@{status--prefix-cls}--compact
        ):not(.@{status--prefix-cls}--flip).@{action--prefix-cls}--size-16
        > .@{action--prefix-cls}__content
        > .@{action--prefix-cls}__icon {
        margin-block-start: 0;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;