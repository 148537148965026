// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../../helpers/mixins";
@import "../Alert/variables";
@import "../Icon/variables";
@import "../Button/variables";
@import "../Toaster/variables";
@import "../ProgressStep/variables";
@import "../Link/variables";

@toast--prefix-cls: ~"@{cls-prefix}toast";

.@{toast--prefix-cls} {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-block: 8px 0;
    margin-inline: 0;
    padding-block: 12px;
    padding-inline: 16px;
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: @token-shadow-2;
    max-inline-size: 400px;
    inline-size: 100%;
    pointer-events: all;

    &:not(&--accent) {
        color: rgb(255 255 255 / 0.9);
        background-color: #1f2023;
    }

    &--accent {
        background-color: @alert--info-background;
    }

    &:focus {
        outline: none;
    }

    &__icon,
    &__action {
        flex: 0 0 auto;
    }

    &__action {
        margin-inline-end: -4px;
        padding-inline-start: 8px;
    }

    &__icon {
        line-height: 1;
        padding-block: 2px;
        padding-inline: 0 6px;
    }

    &__content {
        flex: 1 1 auto;
        word-wrap: break-word;
        overflow-wrap: break-word;
        overflow: hidden;
    }

    &:not(&--accent) a:not(.@{button--prefix-cls}):not(.@{link--prefix-cls}) {
        color: @link--on-dark-color;

        &:hover {
            color: @link--on-dark-hover-color;
        }

        &:active {
            color: @link--on-dark-active-color;
        }
    }

    &--enter,
    &--enter ~ & {
        transform: translateY(-40px);
    }

    &--enter-active,
    &--enter-active ~ & {
        transform: translateY(0);
        transition: transform 0.3s cubic-bezier(0.54, 1.12, 0.38, 1.11);
    }

    &--exit {
        opacity: 1;
        filter: blur(0);
    }

    &--exit-active {
        opacity: 0;
        filter: blur(10px);
        transition: opacity 0.3s cubic-bezier(0.4, 1, 0.75, 0.9),
            filter 0.3s cubic-bezier(0.4, 1, 0.75, 0.9);
    }

    &--exit ~ & {
        transform: translateY(0);
    }

    &--exit-active ~ & {
        transform: translateY(-40px);
        transition: transform 0.1s cubic-bezier(0.4, 1, 0.75, 0.9) 0.05s;
    }

    .@{toaster--prefix-cls}--bottom-end & {
        &--enter,
        &--enter ~ .@{toast--prefix-cls} {
            transform: translateY(40px);
        }

        &--enter-active,
        &--enter-active ~ .@{toast--prefix-cls} {
            transform: translateY(0);
        }

        &--exit ~ .@{toast--prefix-cls} {
            transform: translateY(0);
        }

        &--exit-active ~ .@{toast--prefix-cls} {
            transform: none;
        }
    }

    .@{button--prefix-cls}&__close {
        vertical-align: top;
        inline-size: 20px;
        block-size: 20px;
        min-inline-size: 20px;
        min-block-size: 20px;
        padding: 4px;
    }

    .@{progress-step--prefix-cls} {
        &__media {
            padding-inline-end: 8px;
        }

        &__title {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;