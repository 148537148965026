// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Icon/variables";

.@{progress-step--prefix-cls} {
    display: flex;
    font-size: 12px;
    line-height: 16px;

    &--on-dark {
        color: rgb(255 255 255 / 0.9);
    }

    &:not(:last-child) {
        margin-block-end: 24px;
    }

    &__media {
        flex: none;
        padding-inline-end: 12px;
    }

    &--success,
    &--inactive {
        color: @progress-step--inactive-color;
    }

    &--success &__media {
        color: @progress-step-media--success-color;
    }

    &--warning &__media {
        color: @progress-step-media--warning-color;
    }

    &--danger &__media {
        color: @progress-step-media--danger-color;
    }

    &--info &__media {
        color: @progress-step-media--background-color;
    }

    &--on-dark &__media,
    &--on-dark&--success,
    &--on-dark&--inactive {
        color: @progress-step--on-dark-inactive-color;
    }

    &--on-dark&--success &__media {
        color: @progress-step-media--on-dark-success-color;
    }

    &--on-dark&--warning &__media {
        color: @progress-step-media--on-dark-warning-color;
    }

    &--on-dark&--danger &__media {
        color: @progress-step-media--on-dark-danger-color;
    }

    &--on-dark&--info &__media {
        color: @progress-step-media--on-dark-color;
    }

    &--content &__media {
        align-self: flex-start;
    }

    &__icon {
        &.@{icon--prefix-cls} {
            vertical-align: top;
        }

        &.@{icon--prefix-cls}--size-16 {
            margin-block-start: 2px;
        }
    }

    &__body {
        flex: 1;
        min-inline-size: 0;
        padding-inline-start: 20px;
    }

    &__media + &__body {
        padding-inline-start: 0;
    }

    &__labels {
        display: flex;
        align-items: flex-end;
    }

    &__labels-body {
        flex: 1;
        min-inline-size: 0;
    }

    &__title {
        flex: auto;
        min-inline-size: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    &__title + &__content {
        margin-block-start: 4px;
    }

    &__status {
        margin-inline-start: auto;
        padding-inline-start: 10px;
        color: @token-color-grey-7;
    }

    &__content {
        margin-block-start: 2px;

        > :last-child {
            margin-block-end: 0;
        }
    }

    &__progress {
        margin-block-start: 9px;
    }

    &__labels + &__progress {
        margin-block-start: 10px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;