// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables.less";

@auxiliary-actions--prefix-cls: ~"@{cls-prefix}auxiliary-actions";

.@{auxiliary-actions--prefix-cls} {
    &,
    a {
        color: @base-secondary-link-color;
    }

    a:hover {
        color: @base-secondary-link-hover-color;
    }

    &::before {
        content: "[";
    }

    &::after {
        content: "]";
    }

    &__separator {
        font-size: 12px;
        vertical-align: 1px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: production;@cssSourceMap: true;