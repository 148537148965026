// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

//
// Utils
// ==========================================================================

.size(@width; @height) {
    inline-size: @width;
    block-size: @height;
}

.square(@size) {
    .size(@size; @size);
}

.inline-svg-background-image(@code) {
    background-image: %(~'url("data:image/svg+xml;charset=utf-8,%a")', escape(@code));
}

// TODO: make component for SVG
.svg-arrow-8-down(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1.8 8 8'><path fill='@{color}' d='M.146.146a.5.5 0 0 1 .708 0L4 3.293 7.146.146a.5.5 0 1 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 0 1 0-.708z'/></svg>");
}
.svg-arrow-8-up(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1.24 8 8'><path fill='@{color}' d='M7.854 4.854a.5.5 0 0 1-.708 0L4 1.707.854 4.854a.5.5 0 0 1-.708-.708l3.5-3.5a.5.5 0 0 1 .708 0l3.5 3.5a.5.5 0 0 1 0 .708z'/></svg>");
}
.svg-arrow-8-left(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-1.24 0 8 8'><path fill='@{color}' d='M4.854.146a.5.5 0 0 1 0 .708L1.707 4l3.147 3.146a.5.5 0 1 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0z'/></svg>");
}
.svg-arrow-8-right(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-1.8 0 8 8'><path fill='@{color}' d='M.146 7.854a.5.5 0 0 1 0-.708L3.293 4 .146.854A.5.5 0 1 1 .854.146l3.5 3.5a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708 0z'/></svg>");
}
.svg-arrow-12-down(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -3 12 12'><path fill='@{color}' d='M11.87.164a.5.5 0 0 1-.034.706l-5.5 5a.5.5 0 0 1-.672 0l-5.5-5A.5.5 0 1 1 .836.13L6 4.824 11.164.13a.5.5 0 0 1 .706.034z'/></svg>");
}
.svg-arrow-12-up(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -3 12 12'><path fill='@{color}' d='M.13 5.836a.5.5 0 0 1 .034-.706l5.5-5a.5.5 0 0 1 .672 0l5.5 5a.5.5 0 1 1-.672.74L6 1.176.836 5.87a.5.5 0 0 1-.706-.034z'/></svg>");
}
.svg-arrow-12-left(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-3 0 12 12'><path fill='@{color}' d='M5.836 11.87a.5.5 0 0 1-.706-.034l-5-5.5a.5.5 0 0 1 0-.672l5-5.5a.5.5 0 1 1 .74.672L1.176 6l4.694 5.164a.5.5 0 0 1-.034.706z'/></svg>");
}
.svg-arrow-12-right(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-3 0 12 12'><path fill='@{color}' d='M.164.13A.5.5 0 0 1 .87.164l5 5.5a.5.5 0 0 1 0 .672l-5 5.5a.5.5 0 1 1-.74-.672L4.824 6 .13.836A.5.5 0 0 1 .164.13z'/></svg>");
}
.svg-arrow-sort-up(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-0.5 0 8 8'><path fill='@{color}' d='M3.188.11a.5.5 0 0 1 .624 0l2.5 2a.5.5 0 1 1-.624.78L4 1.54V7.5a.5.5 0 0 1-1 0V1.54L1.312 2.89a.5.5 0 1 1-.624-.78l2.5-2z'/></svg>");
}
.svg-arrow-sort-down(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-0.5 0 8 8'><path fill='@{color}' d='M3.188 7.89a.5.5 0 0 0 .624 0l2.5-2a.5.5 0 1 0-.624-.78L4 6.46V.5a.5.5 0 0 0-1 0v5.96L1.312 5.11a.5.5 0 1 0-.624.78l2.5 2z'/></svg>");
}
.svg-search(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-2 -2 16 16'><path fill='@{color}' d='M4.461 0a4.462 4.462 0 1 0 0 8.923 4.462 4.462 0 0 0 0-8.923zm0 1.622a2.842 2.842 0 0 1 2.84 2.84A2.842 2.842 0 0 1 4.46 7.3a2.842 2.842 0 0 1-2.838-2.84 2.842 2.842 0 0 1 2.839-2.839zm6.28 10.258L7.596 8.736l1.14-1.14 3.145 3.144a.406.406 0 0 1 0 .573l-.566.567a.404.404 0 0 1-.574 0z'/></svg>");
}
.svg-cross(@color) {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-2 -2 16 16'><path fill='@{color}' d='M.146.146a.5.5 0 0 1 .708 0L6 5.293 11.146.146a.5.5 0 0 1 .708.708L6.707 6l5.147 5.146a.5.5 0 0 1-.708.708L6 6.707.854 11.854a.5.5 0 0 1-.708-.708L5.293 6 .146.854a.5.5 0 0 1 0-.708z'/></svg>");
}
.svg-icon-info(@color: "#4cccff") {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='@{color}' fill-rule='evenodd' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM9 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM6.5 6a.5.5 0 0 0 0 1H7v4h-.5a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H9V6H6.5z'/></svg>");
}
.svg-icon-success(@color: "#aee645") {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='@{color}' fill-rule='evenodd' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm3.78-9.375a1 1 0 0 0-1.56-1.25L7 9.4 5.78 7.876a1 1 0 1 0-1.56 1.249l2 2.5a1 1 0 0 0 1.56 0l4-5z'/></svg>");
}
.svg-icon-warning(@color: "#ffa04d") {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 16 16'><path fill='@{color}' d='M8.945.551a1.085 1.085 0 0 0-1.89 0L.166 12.235C-.289 13.007.243 14 1.111 14h13.778c.868 0 1.4-.993.945-1.765L8.944.551zM7 5a1 1 0 1 1 2 0v3a1 1 0 1 1-2 0V5zm2 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/></svg>");
}
.svg-icon-danger(@color: "#ff4d6d") {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='@{color}' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 4a1 1 0 0 1 2 0v5a1 1 0 0 1-2 0V4zm2 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/></svg>");
}
.svg-icon-spinner(@color: "#4cccff") {
    .inline-svg-background-image("<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'><circle cx='8' cy='8' r='7' stroke='@{color}' stroke-dasharray='36 7' stroke-dashoffset='-7' stroke-linecap='round' stroke-width='2'/></svg>");
}
